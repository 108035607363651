<template>
  <div class="mt-3">
    <span>  Join The </span> <span class="text-bold"> $150 Million Dollar </span> <span> per Year Community </span>
  </div>
</template>

<script>

export default {
  components: {

  },

  data: function () {
    return {
    }
  },

  methods: {
  }
}
</script>

<style scoped lang="scss">
.text-bold {
  font-weight: bold !important;
}
</style>
